body {
  font-family: 'Source Code Pro', monospace;
  margin: 0;
  padding: 0;
  background: rgb(30, 30, 30);
}

#root-body {
  color: #FFF;
}

.tek-font {
  font-family: 'Source Sans Pro', sans-serif;
}
.tek-font-light {
  font-family: 'Source Sans Pro', sans-serif;
}

.font-size-1 {
  font-size: 1rem;
}
.font-size-2 {
  font-size: 1.25rem;
}
.font-size-3 {
  font-size: 1.5rem;
}
.font-size-4 {
  font-size: 1.75rem;
}
.font-size-5 {
  font-size: 2rem;
}

.ttyl-grey {
  background-color: rgba(64,63,76,.75) !important;
}

.ttyl-blue-text {
  color: #03a9f4 !important;
}

.side-nav {
  background-color: rgba(64, 63, 76, 0.75);
  text-align: center;
}

.side-nav button {
  width: 100%;
}

/* parallax */

.fade-ani{opacity:0;position:relative;}
.fade-up{bottom:-50px;}
.fade-down{top:-50px;}
.fade-left{left:-50px;}
.fade-right{right:-50px;}

.fade-ani.showing{opacity: 1;}
.fade-in.showing{opacity:1;}
.fade-up.showing{bottom:0px;opacity:1;}
.fade-down.showing{top:-0px;opacity:1;}
.fade-left.showing{left:-0px;opacity:1;}
.fade-right.showing{right:-0px;opacity:1}
.fade-panel {
  transition:all ease-out 1s 0s;
}

.button-collapse {
  color: #000;
}

.fluid {
  width: 100%;
}

.bolded {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.secondary-content {
  color: #607d8b;
}

.tabs {
  background-color: rgba(64, 63, 76, 0.75);
}
.tabs .tab {
  padding: 3px 0 0 0;
  font-style: normal;
}
.tabs .tab.active {
  background-color: rgba(64, 63, 76, 0.75);
}
.tabs .tab a {
  color: #FFF;;
}
.tabs .tab a:hover, .tabs .tab a.active {
  color: #FFF;
}
.tabs .indicator {
  color: #03a9f4;
  background-color: #000;
}

a {
  color: #FFF;
}

.collection {
  border: 0;
}
.collection-item {
  border: 0;
}

.collection .collection-item.active {
  background-color: #000;
}

.collection a.collection-item {
  color: #03a9f4;
}

.btn, .btn-large {
  background-color: #000;
}

.btn:hover, .btn-large:hover {
  background-color: rgba(64, 63, 76, 0.80)
}

.highcharts-credits {
  display: none !important;
}

.card {
  background-color: #403f4c;
}

.card-title {
  font-weight: bold !important;
  font-family: 'Armino', sans-serif;
}

.collapsible, .collapsible-body {
  margin: 0;
  border: 0;
  padding: 0;
}

.secondary-content {
  color: #03a9f4;
}

.btn-floating {
  z-index: 2;
}

.raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

/* ShowsListItem */

.show-wrapper, .news-wrapper {
  margin: 20px 0;
}
.show-wrapper .col {
  padding: 0;
}

.show-date {}

.show-host {
  height: 54px;
}

.show-title {
  margin: 0;
  min-height: 75px;
  font-family: 'Armino', sans-serif;
}

.show-desc {
  position : relative;
}

.extended:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom,
                    rgba(064,63,76, 0),
                    rgba(64,63,76, 1) 90%);
  width    : 100%;
  height   : 4em;
}

@media screen and (max-width: 678px) {
  .show-card {
    min-height: 485px !important;
  }
}

@media screen and (max-width: 350px) {
  .show-wrapper {
    min-height: 0 !important;
  }
  .show-card {
    min-height: 0 !important;
  }
}

.modal {
  top: 10vh;
  width: 75vh;
}

.margin {
  margin: 10px !important;
}
.margin-2 {
  margin: 20px !important;
}
.margin-t {
  margin-top: 10px !important;
}
.margin-t-2 {
  margin-top: 20px !important;
}
.margin-b-1 {
  margin-bottom: 5px !important;
}
.margin-b {
  margin-bottom: 10px !important;
}
.margin-b-2 {
  margin-bottom: 20px !important;
}
.margin-l {
  margin-left: 10px !important;
}
.margin-l-2 {
  margin-left: 20px !important;
}
.margin-r {
  margin-right: 10px !important;
}
.margin-r-2 {
  margin-right: 20px !important;
}
.margin-tb {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-tb-2 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.margin-lr {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.margin-lr-2 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.margin-0 {
  margin: 0 !important;
}
.margin-b-0 {
  margin-bottom: 0 !important;
}
.margin-t-0 {
  margin-top: 0 !important;
}
.margin-l-0 {
  margin-left: 0 !important;
}
.margin-r-0 {
  margin-right: 0 !important;
}
.margin-tb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-lr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pad {
  padding: 10px !important;
}
.pad-2 {
  padding: 20px !important;
}
.pad-l {
  padding-left: 10px !important;
}
.pad-l-2 {
  padding-left: 20px !important;
}
.pad-r {
  padding-right: 10px !important;
}
.pad-r-2 {
  padding-right: 20px !important;
}
.pad-lr {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.pad-lr-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pad-b {
  padding-bottom: 10px !important;
}
.pad-b-2 {
  padding-bottom: 20px !important;
}
.pad-t {
  padding-top: 10px !important;
}
.pad-t-2 {
  padding-top: 20px !important;
}
.pad-tb {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pad-tb-2 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pad-1 {
  padding: 5px !important;
}
.pad-t-1 {
  padding-top: 5px !important;
}
.pad-b-1 {
  padding-bottom: 5px !important;
}
.pad-l-1 {
  padding-left: 5px !important;
}
.pad-r-1 {
  padding-right: 5px !important;
}
.pad-tb-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.pad-lr-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pad-0 {
  padding: 0 !important;
}
.pad-l-0 {
  padding-left: 0 !important;
}
.pad-r-0 {
  padding-right: 0 !important;
}
.pad-lr-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.pad-b-0 {
  padding-bottom: 0 !important;
}
.pad-t-0 {
  padding-top: 0 !important;
}
.pad-tb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.border-0 {
  border: 0 !important;
}

.ttyl-modal {
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.ttyl-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 63, 76, 0.80) !important;
  z-index: 1;
}

.ttyl-modal-content {
  z-index: 2;
  position: relative;
}
